#analytics {
    background-color: #f5f7fa;
}

.title {
    font-family: Lato;
    color: #1b253a;
    letter-spacing: 0.7px;
    font-weight: bold;
}

.outsideContainer {
    font-size: 25px;
}

.inContainer {
    font-size: 14px;
}

.chart {
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.filterInput {
    width: 18%;
    padding-left: 15px;
    height: 40px;
    /*border-color: #979797;*/
    box-shadow: 0px 0px 5px 0px rgba(232, 232, 232, 0.53);
}

.filterInputMobile {
    width: 35%;
    height: 40px;
    text-align: left;
    /*border-color: #979797;*/
    box-shadow: 0px 0px 5px 0px rgba(232, 232, 232, 0.53);
}

.granularityInput {
    height: 40px;
    width: 10%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.granularityInputMobile {
    height: 40px;
    width: 20%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.sourceInput {
    height: 40px;
    width: 15%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.sourceInputMobile {
    height: 40px;
    width: 25%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}