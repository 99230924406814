.rental-report-wrapper {
  background: #ffffff;
  margin: 35px auto;
  width: 80%;
}

.rental-report-wrapper .ant-form-item-label {
  line-height: 15px;
}

.report-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  padding: 25px;
  color: #252525;
}

.border-btm {
  padding-left: 25px 25px;
  border-bottom: solid 1px #979797;
}

.address-title {
  display: block;
  font-size: 24px;
  font-weight: 500;
  color: #9b9b9b;
  padding-top: 24px;
}

.rental-report-wrapper .top-input .ant-form-item {
  margin-bottom: 25px;
}

.rental-report-wrapper .ant-col {
  position: unset;
}

.rental-report-wrapper .ant-select {
  width: 100%;
}

.rental-report-wrapper .top-input .address-input-wrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 64% 34%;
  grid-gap: 0px 20px;
}

.rental-report-wrapper .top-input .left-form-wrapper {
  width: 50%;
}

.rental-report-wrapper .bottom-input .left-form-wrapper {
  display: flex;
}

.border-btm {
  border-bottom: solid 1px #979797;
}

.rental-report-wrapper .bottom-input .delete-room {
  position: absolute;
  right: 0;
  bottom: -9px;
  color: #d33131;
  border: solid 1px #d33131;
  padding: 8px;
  border-radius: 9px;
  cursor: pointer;
}

.rental-report-wrapper .top-input .right-form-wrapper {
  width: 50%;
}

.rental-report-wrapper .left-form-wrapper .ant-form-item-control-wrapper {
  position: absolute;
  right: 0;
}

.rental-report-wrapper .right-form-wrapper .ant-form-item-control-wrapper {
  position: absolute;
  right: 0;
}

.rental-report-inner {
  font-size: 15px;
  width: 100%;
}

.room-input {
  border-bottom: 1px solid #979797;
  padding-bottom: 40px;
}

.rental-report-wrapper .top-input {
  /* border-bottom: solid 1px #979797;
  padding-bottom: 30px; */
}

.rental-report-wrapper .room-title {
  font-size: 15px;
  font-weight: 800;
  padding-top: 30px;
  position: relative;
  margin-bottom: 25px;
}

.report-result-wrapper .room-title {
  font-size: 15px;
  font-weight: 800;
  position: relative;
  margin-bottom: 25px;
}

.report-result-wrapper .amenities-input-wrapper {
  grid-gap: 20px 20px;
  font-size: 15px;
}

.amenities-input-wrapper {
  display: grid;
  grid-template-columns: 33% 30% 34%;
  grid-gap: 0 20px;
}

.rental-report-inner .bottom-input .button-add-room {
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  color: #66b015;
  left: 25px;
  position: absolute;
  border: solid 1px #66b015;
  margin-top: 32px;
  font-weight: 500;
  height: 44px;
}

.rental-email {
  position: relative;  
  margin-top: 60px;
  padding-top: 20px;
  border-top: solid 1px #979797;
}

.create-report-wrapper {
  position: relative;
  height: 200px;
  /*margin-top: 60px;*/
  padding-top: 20px;
  /*border-top: solid 1px #979797;*/
}

.create-report-wrapper .create-report-button {
  background: #66b015;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  width: 192px;
  height: 50px;
  position: absolute;
  right: 25px;
}

.report-result-wrapper {
  width: 80%;
  margin: auto;
}

.report-section-title {
  font-size: 20px;
  font-weight: 500;
  color: #414850;
  margin-top: 35px;
  margin-bottom: 25px;
  display: block;
}

.bottom-line {
  border-bottom: 1px solid #979797;
  padding-bottom: 35px;
}

.report-comparables-section .room-title {
  font-size: 15px;
  font-weight: 800;
  color: #4a4a4a;
  display: block;
  padding-bottom: 0;
  padding-top: 50px;
}

.report-comparables-section .ant-table-body {
  padding-bottom: 50px;
}

.report-comparables-section .table-detail .ant-table-thead > tr > th {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
  background: #ffffff;
}

.trend-down-btn {
  border: solid 1px #ff0000;
  color: #ff0000;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.trend-up-btn {
  border: solid 1px #66b015;
  color: #66b015;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.trend-netral-btn {
  border: solid 1px #414850;
  color: #414850;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.report-comparables-section .table-detail .ant-table-tbody > tr > td {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
}

.historical-comparables-section .table-detail .ant-table-thead > tr > th {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
  background: #ffffff;
}

.historical-comparables-section .table-detail .ant-table-tbody > tr > td {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
}

.report-comparables-section .table-detail .ant-table-tbody > tr:nth-child(odd) {
  background: #f5f5f5;
}

.report-result-top .report-detail {
  font-size: 15px;
  font-weight: normal;
  width: 300px;
  margin-right: 50px;
  color: #000000;
}
.b-500 {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.report-result-top .input-title-param {
  width: 150px;
  display: inline-block;
  color: #a7a7a7;
  font-weight: 500;
}

.report-breakdown-section .input-title-param {
  display: inline-block;
  color: #a7a7a7;
  font-weight: 500;
  padding-right: 10px;
}

.report-breakdown-section .report-detail {
  font-size: 15px;
  font-weight: normal;
  position: relative;
  margin-right: 25px;
  color: #000000;
}

.breakdown-table {
  width: 100%;
  font-family: AvenirNext, Helvetica;
}

.breakdown-table th {
  font-family: AvenirNext, Helvetica;
  text-align: center;
  color: #4a4a4a;
  font-weight: 500;
}

.breakdown-table td {
  font-family: AvenirNext, Helvetica;
  font-size: 16px;
}

.breakdown-table tr {
  font-family: AvenirNext, Helvetica;
}

.total-estimated-result {
  position: absolute;
  right: 30px;
  bottom: 28px;
  font-weight: 800;
  color: #4a4a4a;
}

.report-breakdown-section .estimated-price {
  padding: 30px 50px;
  border: solid 1px #979797;
  margin-bottom: -1px;
}

.report-breakdown-section .estimated-price.average {
  background: #f2f2f2;
  border: solid 1px #66b015;
  font-weight: 800;
}

.report-result-top .report-detail div {
  margin-top: 16px;
}

.abs-right {
  position: absolute;
  right: 0;
}

.report-result-top .result-title {
  padding-top: 40px;
  display: flex;
  position: relative;
}

.report-result-top .right-price {
  right: 0;
  position: absolute;
  text-align: center;
  border: 1px solid #66b015;
  padding: 9px 20px;
}

.report-result-top .print-button {
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 15px;
  display: flex;
  border: solid 1px #66b015;
  padding: 10px 16px;
  background-color: #f0f8e8;
}

.report-result-top .right-price .estimated-rent {
  font-size: 20px;
  font-weight: 500;
  display: block;
  padding-bottom: 16px;
}

.report-result-top .right-price .estimated-price {
  font-size: 30px;
  font-weight: 800;
  color: #66b015;
}
