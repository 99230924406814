.paginationButtonSelected {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    line-height: 40px;
    border-width: 1px;    
    border-color: #94979b;
    color: #52575a;
}

.paginationButton {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    border-width: 0px;
    line-height: 40px;    
    color: #52575a
}

.actionButton {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    line-height: 40px;   
    border-width: 0px; 
    color:#52575a;
    background-color: transparent;
}

.viewMarkerButton {
    font-family: Lato;    
    font-size: 16px;
    align-content: center;    
    font-weight: normal;
    border-width: 0px;
    color: #66b015;     
    background-color: transparent;    
}

.searchesHeader {
    background-color: white;
}

th {
    font-family: Lato;
    font-size: 16px;
    text-align: left;
    color: #94979b;
}

td {
    font-family: Lato;
    font-size: 14px;
    text-align: left;
    color: #52575a;
}

.selectedSearch {    
    border: 3px solid #66b015;    
    border-top: 2px solid #66b015;
    border-right: 3px solid #66b015;
    border-bottom: 2px solid #66b015;        
}