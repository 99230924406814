.top-toggle {
  cursor: pointer;
  padding: 4px 13px;
  border: 1px solid #66b015;
  position: absolute;
  right: 0;
  background: #f0f8e8;
  border-radius: 4px;
}
.filter-title {
  padding-top: 4px;
  margin-left: 10px;
  position: relative;
}
.filter-title .title {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #252525;
}
.filter-title .btn-reset {
  border: 1px solid #66b015;
  border-radius: 6px;
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66b015;
  padding: 10px;
  position: absolute;
  right: 0;
  top: -8px;
  cursor: pointer;
}
.ant-col.ant-col-12.pad10 {
  padding-right: 10px;
}
.search-mobile .ant-checkbox-wrapper {
  margin-top: 15px !important;
}
.ant-drawer-close {
  left: 0;
}
.search-mobile .btn-estimate-rent {
  width: 100%;
  border-radius: 0;
}
.search-mobile .input-title {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #252525;
  display: block;
}
.search-mobile .ant-checkbox-group-item {
  width: 50% !important;
}
.search-mobile .ant-checkbox-group {
  display: flex !important;
  width: 100% !important;
}
.border-btm {
  padding: 25px 25px;
  border-bottom: solid 1px #979797;
}
.ant-drawer-body {
  padding: 0 !important;
}
.search-mobile .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}
.sticky {
  position: fixed;
  bottom: 20px;
  width: 100%;
  color: #66b015;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
}

.mobile-sticky {
  display: inline-block;
  padding: 5px 10px;
  background: #f0f8e8;
  border-radius: 6px;
  border: 1px solid #66b015;
  cursor: pointer;
}
