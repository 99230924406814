.overlay-container {
    position: absolute;
    left: 3%;
    top: 12%;
    width: 15%;
}

.overlay-container-mobile {
    position: relative;
    left: 5%;
    top: -420px;
    width: 200px;    
}

/* .overlayMobile {
    position: relative;
    left: 5%;
    top: -425px;
    width: 200px;    
    background-color: white;    
 } */

 /*
   top: -425px;
   top: -475px;   
*/

.overlayUser {
   position: static;         
   background-color: white;      
}

.overlayUser-mobile {
    position: static;         
    background-color: white;      
    height: 175px;
}

.overlayEstimate {
    position: static;
    margin-top: 20px;        
    background-color: white;   
 }

 .overlayEstimate-mobile {
    position: static;
    margin-top: 20px;        
    background-color: white;   
    height: 150px;
 }

.title {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #1b253a;
    padding-top: 10px;
    padding-left: 20px;
}

.title-user {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #9b9b9b;
    padding-left: 20px;
    padding-top: 10px;        
}

.option {
    width: 100%;
    padding: 5px 0px 0px 20px;    
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #94979b;    
}

.option-user {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #9b9b9b;
    padding-left: 20px;    
}

.option-value {
    padding-left: 10px;
    font-family: Lato;
    font-weight: normal;
    font-size: 14px;
    color: #52575a;
}

.option-user-value {
    padding-left: 20px;
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: black;
}

.option-average {
    padding-left: 10px;
    font-family: Lato;
    font-weight: bolder;
    font-size: 14px;
    color: #66b015;
}