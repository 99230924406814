.error-bottom-input .ant-input {
  border: 1px solid #969696;
  border-radius: 9px;
  height: 40px;
}

.error-bottom-input .ant-select-selection {
  border: 1px solid #969696;
  border-radius: 9px;
  height: 40px;
}

.error-bottom-input .ant-select {
  width: 100%;
}

.error-bottom-input .predict-param-wrapper {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-label {
  display: block;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-control-wrapper {
  margin-right: 10px;
}

.error-bottom-input .predict-param-wrapper .search-wrapper {
  cursor: pointer;
  background-color: #68ae28;
  color: #ffffff;
  border-radius: 5px;
  width: 91px;
  height: 50px;
  margin: auto;
  font-size: 20px;
}

.error-bottom-input
  .address-wrapper
  .ant-input-affix-wrapper
  .ant-input:not(:first-child) {
  padding-left: 60px;
}

.error-bottom-input
  .predict-param-wrapper
  .ant-select-selection--single
  .ant-select-selection__rendered {
  margin-top: 5px;
}

.available-cross-the-world {
  font-size: 35px;
  font-weight: 900;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-control {
  line-height: 0;
}

.error-bottom-input .predict-param-wrapper .ant-form-item {
  width: 100%;
}

.error-bottom-input .address-wrapper {
  width: 100%;
}

.error-bottom-input .toggle-input {
  border: 1px solid #969696;
  border-radius: 9px;
  margin-left: 14px;
}
