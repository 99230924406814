.address-marker-wrapper {
  margin-bottom: 10px;
  font-weight: bold;
}

.btn-estimate {
  border: solid 1px #66b015;
  background: #66b015;
  color: white !important;
}

.navigation-modal .ant-modal-title {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 800;
}

.navigation-modal .navigation-modal-message {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: normal;
  color: #4a4a4a;
}

.navigation-modal .navigation-modal-cancel {
  position: absolute;
  left: 0;
  background: #ffffff;
  border: 1px solid #767675;
  border-radius: 4px;
  width: 200px;
  padding: 10px;
  color: #252525;
  outline: none !important;
}

.navigation-modal .navigation-modal-proceed {
  position: absolute;
  right: 0;
  background: #66b015;
  border: 1px solid #66b015;
  border-radius: 4px;
  width: 200px;
  padding: 10px;
  color: #ffffff;
  outline: none !important;
}

#map-container .map-wrapper {
  height: 100%;
}

.btn-outline-success:hover {
  background: #66b015;
  border: 1px solid #66b015;
}

.share-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.share-icon-wrapper .pointer {
  cursor: pointer;
}