.input-title {
  display: block;
  text-align: left;
  font-size: 16px;
}

.searchFormWrapper .ant-col-3 {
  margin-left: 10px;
}

.estimate-wrapper .btn-estimate-rent {
  position: absolute;
  right: 0;
}

.btn-estimate-rent {
  color: white;
  width: 192px;
  height: 30px;
  background: #66b015;
  border-radius: 9px;
  outline: none !important;
  border: solid 1px transparent;
}

.room-wrapper {
  margin: 12px;
  border: solid 0.5px #9b9b9b;
  font-size: 12px;
  color: #9b9b9b;
  border-radius: 4px;
}

.map-wrapper {
  width: 100%;
  height: 85vh;
}

.room-wrapper .icon-wrapper {
  margin: 12px;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.room-wrapper .price-wrapper {
  margin: 6px;
  margin-left: 12px;
  font-size: 16px;
  color: #000000;
}
.room-source-icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(67, 64, 61, 0.8);
  width: 32px;
  height: 24px;
  text-align: center;
}
.search-btn {
  font-size: 12px;
  width: 76px;
  height: 32px;
  border: 1px solid #66b015;
  background: #ffffff;
  color: #66b015;
  border-radius: 4px;
  outline: none !important;
}
.room-wrapper .address-wrapper {
  margin: 6px;
  margin-left: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
}

.room-wrapper .contact-property-wrapper {
  margin: 6px;
  margin-left: 12px;
}

.room-list-wrapper {
  height: 85vh;
  overflow-y: auto;
}

.search-price-marker {
  font-size: 12px;
  font-weight: 900;
  color: #66b015;
  width: 75px;
  background: white;
  border: 1px solid #66b015;
  border-radius: 4px;
  padding: 2px 7px 2px 7px;
  text-align: center;
}

.transparent .ant-collapse-header {
  opacity: 0.5;
}

.property-type {
  height: 32px;
  padding: 4px 16px;
  border: solid 1px red;
  border-radius: 4px;
}

.searchFormWrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 16px;
}

.property-type-collapse {
  height: 32px;
  background: white;
  font-size: 12px;
  border: 0.5px solid #9b9b9b;
  border-radius: 4px !important;
}

.searchFormWrapper .ant-input {
  border: 0.5px solid #9b9b9b;
}

.price-popover {
  width: 192px;
  margin: -5px;
}

.searchFormWrapper .ant-select-selection__placeholder {
  color: #4a4a4a;
}

.ant-popover-placement-bottomLeft {
  padding-top: 0px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.searchFormWrapper .ant-select-selection {
  border: 0.5px solid #9b9b9b;
}

.property-type-collapse .text-input {
  margin: 6px 12px;
  display: flex;
}

.property-type-collapse .text-input .arrow {
  position: absolute;
  right: 5px;
}

.more-type-collapse .ant-collapse-content-active {
  position: absolute;
  z-index: 1000;
  margin-top: 8px;
  width: 256px;
  border: 0.5px solid #9b9b9b;
  border-radius: 4px !important;
  font-size: 12px;
}

.more-popover-content {
  width: 200px;
}

.more-popover-content .ant-checkbox-wrapper {
  font-size: 12px;
  margin-top: 10px;
}

.more-type-collapse {
  background: white;
}

.property-type-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 9px 13px;
}

.more-type-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 9px 13px;
}

.property-popover-content .ant-checkbox-group-item {
  display: block;
  margin-top: 10px;
}

.property-popover-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}

.more-popover-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}

.more-popover-content .right-checkbox {
  position: absolute;
  right: 15px;
  width: 100px;
}

.more-type-title {
  font-weight: 800;
  color: #4a4a4a;
}

.error-bottom-input .ant-input {
  border: 1px solid #969696;
  border-radius: 9px;
  height: 40px;
}

.error-bottom-input .ant-select-selection {
  border: 1px solid #969696;
  border-radius: 9px;
  height: 40px;
}

.error-bottom-input .ant-select {
  width: 100%;
}

.error-bottom-input .predict-param-wrapper {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-label {
  display: block;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-control-wrapper {
  margin-right: 10px;
}

.error-bottom-input .predict-param-wrapper .search-wrapper {
  cursor: pointer;
  background-color: #68ae28;
  color: #ffffff;
  border-radius: 5px;
  width: 91px;
  height: 50px;
  margin: auto;
  font-size: 20px;
}

.error-bottom-input
  .address-wrapper
  .ant-input-affix-wrapper
  .ant-input:not(:first-child) {
  padding-left: 60px;
}

.error-bottom-input
  .predict-param-wrapper
  .ant-select-selection--single
  .ant-select-selection__rendered {
  margin-top: 5px;
}

.available-cross-the-world {
  font-size: 35px;
  font-weight: 900;
}

.error-bottom-input .predict-param-wrapper .ant-form-item-control {
  line-height: 0;
}

.error-bottom-input .predict-param-wrapper .ant-form-item {
  width: 100%;
}

.error-bottom-input .address-wrapper {
  width: 100%;
}

.error-bottom-input .toggle-input {
  border: 1px solid #969696;
  border-radius: 9px;
  margin-left: 14px;
}

.top-toggle {
  cursor: pointer;
  padding: 4px 13px;
  border: 1px solid #66b015;
  position: absolute;
  right: 0;
  background: #f0f8e8;
  border-radius: 4px;
}
.filter-title {
  padding-top: 4px;
  margin-left: 10px;
  position: relative;
}
.filter-title .title {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #252525;
}
.filter-title .btn-reset {
  border: 1px solid #66b015;
  border-radius: 6px;
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66b015;
  padding: 10px;
  position: absolute;
  right: 0;
  top: -8px;
  cursor: pointer;
}
.ant-col.ant-col-12.pad10 {
  padding-right: 10px;
}
.search-mobile .ant-checkbox-wrapper {
  margin-top: 15px !important;
}
.ant-drawer-close {
  left: 0;
}
.search-mobile .btn-estimate-rent {
  width: 100%;
  border-radius: 0;
}
.search-mobile .input-title {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #252525;
  display: block;
}
.search-mobile .ant-checkbox-group-item {
  width: 50% !important;
}
.search-mobile .ant-checkbox-group {
  display: flex !important;
  width: 100% !important;
}
.border-btm {
  padding: 25px 25px;
  border-bottom: solid 1px #979797;
}
.ant-drawer-body {
  padding: 0 !important;
}
.search-mobile .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}
.sticky {
  position: fixed;
  bottom: 20px;
  width: 100%;
  color: #66b015;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
}

.mobile-sticky {
  display: inline-block;
  padding: 5px 10px;
  background: #f0f8e8;
  border-radius: 6px;
  border: 1px solid #66b015;
  cursor: pointer;
}

.address-marker-wrapper {
  margin-bottom: 10px;
  font-weight: bold;
}

.btn-estimate {
  border: solid 1px #66b015;
  background: #66b015;
  color: white !important;
}

.navigation-modal .ant-modal-title {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: 800;
}

.navigation-modal .navigation-modal-message {
  font-family: AvenirNext, Helvetica;
  font-style: normal;
  font-weight: normal;
  color: #4a4a4a;
}

.navigation-modal .navigation-modal-cancel {
  position: absolute;
  left: 0;
  background: #ffffff;
  border: 1px solid #767675;
  border-radius: 4px;
  width: 200px;
  padding: 10px;
  color: #252525;
  outline: none !important;
}

.navigation-modal .navigation-modal-proceed {
  position: absolute;
  right: 0;
  background: #66b015;
  border: 1px solid #66b015;
  border-radius: 4px;
  width: 200px;
  padding: 10px;
  color: #ffffff;
  outline: none !important;
}

#map-container .map-wrapper {
  height: 100%;
}

.btn-outline-success:hover {
  background: #66b015;
  border: 1px solid #66b015;
}

.share-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.share-icon-wrapper .pointer {
  cursor: pointer;
}
.rental-report-wrapper {
  background: #ffffff;
  margin: 35px auto;
  width: 80%;
}

.rental-report-wrapper .ant-form-item-label {
  line-height: 15px;
}

.report-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  padding: 25px;
  color: #252525;
}

.border-btm {
  padding-left: 25px 25px;
  border-bottom: solid 1px #979797;
}

.address-title {
  display: block;
  font-size: 24px;
  font-weight: 500;
  color: #9b9b9b;
  padding-top: 24px;
}

.rental-report-wrapper .top-input .ant-form-item {
  margin-bottom: 25px;
}

.rental-report-wrapper .ant-col {
  position: unset;
}

.rental-report-wrapper .ant-select {
  width: 100%;
}

.rental-report-wrapper .top-input .address-input-wrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 64% 34%;
  grid-gap: 0px 20px;
}

.rental-report-wrapper .top-input .left-form-wrapper {
  width: 50%;
}

.rental-report-wrapper .bottom-input .left-form-wrapper {
  display: flex;
}

.border-btm {
  border-bottom: solid 1px #979797;
}

.rental-report-wrapper .bottom-input .delete-room {
  position: absolute;
  right: 0;
  bottom: -9px;
  color: #d33131;
  border: solid 1px #d33131;
  padding: 8px;
  border-radius: 9px;
  cursor: pointer;
}

.rental-report-wrapper .top-input .right-form-wrapper {
  width: 50%;
}

.rental-report-wrapper .left-form-wrapper .ant-form-item-control-wrapper {
  position: absolute;
  right: 0;
}

.rental-report-wrapper .right-form-wrapper .ant-form-item-control-wrapper {
  position: absolute;
  right: 0;
}

.rental-report-inner {
  font-size: 15px;
  width: 100%;
}

.room-input {
  border-bottom: 1px solid #979797;
  padding-bottom: 40px;
}

.rental-report-wrapper .top-input {
  /* border-bottom: solid 1px #979797;
  padding-bottom: 30px; */
}

.rental-report-wrapper .room-title {
  font-size: 15px;
  font-weight: 800;
  padding-top: 30px;
  position: relative;
  margin-bottom: 25px;
}

.report-result-wrapper .room-title {
  font-size: 15px;
  font-weight: 800;
  position: relative;
  margin-bottom: 25px;
}

.report-result-wrapper .amenities-input-wrapper {
  grid-gap: 20px 20px;
  font-size: 15px;
}

.amenities-input-wrapper {
  display: grid;
  grid-template-columns: 33% 30% 34%;
  grid-gap: 0 20px;
}

.rental-report-inner .bottom-input .button-add-room {
  background: #ffffff;
  border-radius: 5px;
  outline: none;
  color: #66b015;
  left: 25px;
  position: absolute;
  border: solid 1px #66b015;
  margin-top: 32px;
  font-weight: 500;
  height: 44px;
}

.rental-email {
  position: relative;  
  margin-top: 60px;
  padding-top: 20px;
  border-top: solid 1px #979797;
}

.create-report-wrapper {
  position: relative;
  height: 200px;
  /*margin-top: 60px;*/
  padding-top: 20px;
  /*border-top: solid 1px #979797;*/
}

.create-report-wrapper .create-report-button {
  background: #66b015;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  width: 192px;
  height: 50px;
  position: absolute;
  right: 25px;
}

.report-result-wrapper {
  width: 80%;
  margin: auto;
}

.report-section-title {
  font-size: 20px;
  font-weight: 500;
  color: #414850;
  margin-top: 35px;
  margin-bottom: 25px;
  display: block;
}

.bottom-line {
  border-bottom: 1px solid #979797;
  padding-bottom: 35px;
}

.report-comparables-section .room-title {
  font-size: 15px;
  font-weight: 800;
  color: #4a4a4a;
  display: block;
  padding-bottom: 0;
  padding-top: 50px;
}

.report-comparables-section .ant-table-body {
  padding-bottom: 50px;
}

.report-comparables-section .table-detail .ant-table-thead > tr > th {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
  background: #ffffff;
}

.trend-down-btn {
  border: solid 1px #ff0000;
  color: #ff0000;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.trend-up-btn {
  border: solid 1px #66b015;
  color: #66b015;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.trend-netral-btn {
  border: solid 1px #414850;
  color: #414850;
  border-radius: 5px;
  width: 100px;
  height: 24px;
}

.report-comparables-section .table-detail .ant-table-tbody > tr > td {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
}

.historical-comparables-section .table-detail .ant-table-thead > tr > th {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
  background: #ffffff;
}

.historical-comparables-section .table-detail .ant-table-tbody > tr > td {
  font-family: AvenirNext;
  font-size: 14px;
  padding: 20px 5px 5px;
}

.report-comparables-section .table-detail .ant-table-tbody > tr:nth-child(odd) {
  background: #f5f5f5;
}

.report-result-top .report-detail {
  font-size: 15px;
  font-weight: normal;
  width: 300px;
  margin-right: 50px;
  color: #000000;
}
.b-500 {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.report-result-top .input-title-param {
  width: 150px;
  display: inline-block;
  color: #a7a7a7;
  font-weight: 500;
}

.report-breakdown-section .input-title-param {
  display: inline-block;
  color: #a7a7a7;
  font-weight: 500;
  padding-right: 10px;
}

.report-breakdown-section .report-detail {
  font-size: 15px;
  font-weight: normal;
  position: relative;
  margin-right: 25px;
  color: #000000;
}

.breakdown-table {
  width: 100%;
  font-family: AvenirNext, Helvetica;
}

.breakdown-table th {
  font-family: AvenirNext, Helvetica;
  text-align: center;
  color: #4a4a4a;
  font-weight: 500;
}

.breakdown-table td {
  font-family: AvenirNext, Helvetica;
  font-size: 16px;
}

.breakdown-table tr {
  font-family: AvenirNext, Helvetica;
}

.total-estimated-result {
  position: absolute;
  right: 30px;
  bottom: 28px;
  font-weight: 800;
  color: #4a4a4a;
}

.report-breakdown-section .estimated-price {
  padding: 30px 50px;
  border: solid 1px #979797;
  margin-bottom: -1px;
}

.report-breakdown-section .estimated-price.average {
  background: #f2f2f2;
  border: solid 1px #66b015;
  font-weight: 800;
}

.report-result-top .report-detail div {
  margin-top: 16px;
}

.abs-right {
  position: absolute;
  right: 0;
}

.report-result-top .result-title {
  padding-top: 40px;
  display: flex;
  position: relative;
}

.report-result-top .right-price {
  right: 0;
  position: absolute;
  text-align: center;
  border: 1px solid #66b015;
  padding: 9px 20px;
}

.report-result-top .print-button {
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 15px;
  display: flex;
  border: solid 1px #66b015;
  padding: 10px 16px;
  background-color: #f0f8e8;
}

.report-result-top .right-price .estimated-rent {
  font-size: 20px;
  font-weight: 500;
  display: block;
  padding-bottom: 16px;
}

.report-result-top .right-price .estimated-price {
  font-size: 30px;
  font-weight: 800;
  color: #66b015;
}

@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
  font-weight: normal;
}

@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNextLTPro-Demi.553decd4.otf);
  font-weight: 500;
}

@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNextLTPro-Bold.91d8ff8a.otf);
  font-weight: bold;
}

/* @font-face {
  font-family: AvenirNext;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-UltLt.otf");
  font-weight: 300;
} */

/* @font-face {
  font-family: AvenirNextSemiBold;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-Demi.otf");
} */

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

body {
  margin: 0;
  font-family: AvenirNext, Helvetica;
}

button:focus{
  outline: none !important;
}

.paginationButtonSelected {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    line-height: 40px;
    border-width: 1px;    
    border-color: #94979b;
    color: #52575a;
}

.paginationButton {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    border-width: 0px;
    line-height: 40px;    
    color: #52575a
}

.actionButton {
    font-family: Lato;
    font-size: 18px;
    align-content: center;
    width: 50px;
    font-weight: normal;
    line-height: 40px;   
    border-width: 0px; 
    color:#52575a;
    background-color: transparent;
}

.viewMarkerButton {
    font-family: Lato;    
    font-size: 16px;
    align-content: center;    
    font-weight: normal;
    border-width: 0px;
    color: #66b015;     
    background-color: transparent;    
}

.searchesHeader {
    background-color: white;
}

th {
    font-family: Lato;
    font-size: 16px;
    text-align: left;
    color: #94979b;
}

td {
    font-family: Lato;
    font-size: 14px;
    text-align: left;
    color: #52575a;
}

.selectedSearch {    
    border: 3px solid #66b015;    
    border-top: 2px solid #66b015;
    border-right: 3px solid #66b015;
    border-bottom: 2px solid #66b015;        
}
.overlay-container {
    position: absolute;
    left: 3%;
    top: 12%;
    width: 15%;
}

.overlay-container-mobile {
    position: relative;
    left: 5%;
    top: -420px;
    width: 200px;    
}

/* .overlayMobile {
    position: relative;
    left: 5%;
    top: -425px;
    width: 200px;    
    background-color: white;    
 } */

 /*
   top: -425px;
   top: -475px;   
*/

.overlayUser {
   position: static;         
   background-color: white;      
}

.overlayUser-mobile {
    position: static;         
    background-color: white;      
    height: 175px;
}

.overlayEstimate {
    position: static;
    margin-top: 20px;        
    background-color: white;   
 }

 .overlayEstimate-mobile {
    position: static;
    margin-top: 20px;        
    background-color: white;   
    height: 150px;
 }

.title {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #1b253a;
    padding-top: 10px;
    padding-left: 20px;
}

.title-user {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #9b9b9b;
    padding-left: 20px;
    padding-top: 10px;        
}

.option {
    width: 100%;
    padding: 5px 0px 0px 20px;    
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #94979b;    
}

.option-user {
    font-family: Lato;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.44px; 
    color: #9b9b9b;
    padding-left: 20px;    
}

.option-value {
    padding-left: 10px;
    font-family: Lato;
    font-weight: normal;
    font-size: 14px;
    color: #52575a;
}

.option-user-value {
    padding-left: 20px;
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: black;
}

.option-average {
    padding-left: 10px;
    font-family: Lato;
    font-weight: bolder;
    font-size: 14px;
    color: #66b015;
}
#analytics {
    background-color: #f5f7fa;
}

.title {
    font-family: Lato;
    color: #1b253a;
    letter-spacing: 0.7px;
    font-weight: bold;
}

.outsideContainer {
    font-size: 25px;
}

.inContainer {
    font-size: 14px;
}

.chart {
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.filterInput {
    width: 18%;
    padding-left: 15px;
    height: 40px;
    /*border-color: #979797;*/
    box-shadow: 0px 0px 5px 0px rgba(232, 232, 232, 0.53);
}

.filterInputMobile {
    width: 35%;
    height: 40px;
    text-align: left;
    /*border-color: #979797;*/
    box-shadow: 0px 0px 5px 0px rgba(232, 232, 232, 0.53);
}

.granularityInput {
    height: 40px;
    width: 10%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.granularityInputMobile {
    height: 40px;
    width: 20%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.sourceInput {
    height: 40px;
    width: 15%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}

.sourceInputMobile {
    height: 40px;
    width: 25%;
    box-shadow: 0 0 5px 0 rgba(232, 232, 232, 0.53);
}
