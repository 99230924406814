.searchFormWrapper .ant-col-3 {
  margin-left: 10px;
}

.estimate-wrapper .btn-estimate-rent {
  position: absolute;
  right: 0;
}

.btn-estimate-rent {
  color: white;
  width: 192px;
  height: 30px;
  background: #66b015;
  border-radius: 9px;
  outline: none !important;
  border: solid 1px transparent;
}

.room-wrapper {
  margin: 12px;
  border: solid 0.5px #9b9b9b;
  font-size: 12px;
  color: #9b9b9b;
  border-radius: 4px;
}

.map-wrapper {
  width: 100%;
  height: 85vh;
}

.room-wrapper .icon-wrapper {
  margin: 12px;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.room-wrapper .price-wrapper {
  margin: 6px;
  margin-left: 12px;
  font-size: 16px;
  color: #000000;
}
.room-source-icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(67, 64, 61, 0.8);
  width: 32px;
  height: 24px;
  text-align: center;
}
.search-btn {
  font-size: 12px;
  width: 76px;
  height: 32px;
  border: 1px solid #66b015;
  background: #ffffff;
  color: #66b015;
  border-radius: 4px;
  outline: none !important;
}
.room-wrapper .address-wrapper {
  margin: 6px;
  margin-left: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
}

.room-wrapper .contact-property-wrapper {
  margin: 6px;
  margin-left: 12px;
}

.room-list-wrapper {
  height: 85vh;
  overflow-y: auto;
}

.search-price-marker {
  font-size: 12px;
  font-weight: 900;
  color: #66b015;
  width: 75px;
  background: white;
  border: 1px solid #66b015;
  border-radius: 4px;
  padding: 2px 7px 2px 7px;
  text-align: center;
}

.transparent .ant-collapse-header {
  opacity: 0.5;
}

.property-type {
  height: 32px;
  padding: 4px 16px;
  border: solid 1px red;
  border-radius: 4px;
}

.searchFormWrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 16px;
}

.property-type-collapse {
  height: 32px;
  background: white;
  font-size: 12px;
  border: 0.5px solid #9b9b9b;
  border-radius: 4px !important;
}

.searchFormWrapper .ant-input {
  border: 0.5px solid #9b9b9b;
}

.price-popover {
  width: 192px;
  margin: -5px;
}

.searchFormWrapper .ant-select-selection__placeholder {
  color: #4a4a4a;
}

.ant-popover-placement-bottomLeft {
  padding-top: 0px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.searchFormWrapper .ant-select-selection {
  border: 0.5px solid #9b9b9b;
}

.property-type-collapse .text-input {
  margin: 6px 12px;
  display: flex;
}

.property-type-collapse .text-input .arrow {
  position: absolute;
  right: 5px;
}

.more-type-collapse .ant-collapse-content-active {
  position: absolute;
  z-index: 1000;
  margin-top: 8px;
  width: 256px;
  border: 0.5px solid #9b9b9b;
  border-radius: 4px !important;
  font-size: 12px;
}

.more-popover-content {
  width: 200px;
}

.more-popover-content .ant-checkbox-wrapper {
  font-size: 12px;
  margin-top: 10px;
}

.more-type-collapse {
  background: white;
}

.property-type-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 9px 13px;
}

.more-type-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 9px 13px;
}

.property-popover-content .ant-checkbox-group-item {
  display: block;
  margin-top: 10px;
}

.property-popover-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}

.more-popover-content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #66b015;
  border-color: #66b015;
}

.more-popover-content .right-checkbox {
  position: absolute;
  right: 15px;
  width: 100px;
}

.more-type-title {
  font-weight: 800;
  color: #4a4a4a;
}
