@import "~antd/dist/antd.css";

@font-face {
  font-family: AvenirNext;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: AvenirNext;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-Demi.otf");
  font-weight: 500;
}

@font-face {
  font-family: AvenirNext;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.otf");
  font-weight: bold;
}

/* @font-face {
  font-family: AvenirNext;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-UltLt.otf");
  font-weight: 300;
} */

/* @font-face {
  font-family: AvenirNextSemiBold;
  src: url("../assets/fonts/AvenirNext/AvenirNextLTPro-Demi.otf");
} */

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

body {
  margin: 0;
  font-family: AvenirNext, Helvetica;
}

button:focus{
  outline: none !important;
}
